import React from 'react'
import { Box } from 'theme-ui'

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 380,
    maxHeight: 514,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `bottom left`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 0,
    left: -70,
    zIndex: 2
  })
}

export default ({content}) => {
  return <Box sx={styles.image(content?.src?.ImageSharp?.images?.fallback?.src)}></Box>
}
