type Props = {
  dataset: Array<String | object>;
  field: String;
  sortArray?: Array<String>;
};

export default ({ dataset, field, sortArray }: Props) => {
  return dataset.sort(function (a, b) {
    const field_a = typeof a === 'string' ? a : a[field];
    const field_b = typeof b === 'string' ? b : b[field];
    return sortArray
      ? (sortArray.findIndex(
          (item) =>
            item.toLowerCase().replaceAll(' ', '') ===
            field_a.toLowerCase().replaceAll(' ', '')
        ) || -1) >
        (sortArray.findIndex(
          (item) =>
            item.toLowerCase().replaceAll(' ', '') ===
            field_b.toLowerCase().replaceAll(' ', '')
        ) || -1)
        ? 1
        : -1
      : field_a > field_b
      ? 1
      : -1;
  });
};
