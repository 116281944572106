import React from 'react'
import { Heading, Box } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default ({content}) => (
  <Box sx={styles.wrapper}>
    <Heading as='h1' sx={styles.heading}>
      {content?.kicker?.text}
    </Heading>
    <Heading as='h3' sx={styles.running}>
      {content?.description?.text}
    </Heading>
    <Heading as='h4'>{content?.text_1?.text}</Heading>
  </Box>
)