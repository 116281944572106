import React from 'react'
import { Heading } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 4,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default ({content}) => (
  <>
    <Section>
      <Heading as='h1' sx={styles.heading}>
        {content.kicker?.text}
      </Heading>
      <Heading as='h3' sx={styles.running}>
        {content.title?.text}
      </Heading>
    </Section>
  </>
)