import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Testimonial.Content'
import Photo from './Testimonial.Photo'

const Testimonial = ({content}) => (
  <HeroBoxed.Wrapper>
    <HeroBoxed.RightColumn customStyle={{flexBasis: '2/5'}}>
      <Photo content={content.avatar} />
    </HeroBoxed.RightColumn>
    <HeroBoxed.LeftColumn customStyle={{flexBasis: '3/5'}}>
      <Content content={{kicker: content.kicker, text_1: content.text_1, description: content.description}} />
    </HeroBoxed.LeftColumn>
    <MemphisPattern />
  </HeroBoxed.Wrapper>
)

export default Testimonial
