import React from 'react'
import { Box, Heading, css } from 'theme-ui'
import { FaUser } from 'react-icons/fa'
import Reveal from '@solid-ui-components/Reveal'
import Counter from '@solid-ui-components/Counter'

const styles = {
  wrapper: {
    display: `flex`,
    bg: `omegaLighter`,
    position: `relative`,
    zIndex: 2,
    p: 3,
  },
  icon: {
    display: `flex`,
    alignItems: `center`,
    mb: 0,
    mx: 3,
    svg: {
      color: `omegaDark`,
      fontSize: 7,
    },
  },
  number: {
    textAlign: `center`,
    fontWeight: `bold`,
    color: `alphaDark`,
    mx: 3,
    mb: 0,
  },
  text: {
    color: `omegaDark`,
    mb: 0,
  },
}
const Stats = ({content: {textGroup}}) => {
  return (
    <Box sx={styles.wrapper}>
      <Heading as='h3' sx={styles.icon}>
        <FaUser />
      </Heading>
      <Heading as='h3' sx={styles.number}>
        <Reveal effect='fadeInGrow' duration={1}>
        <Counter to={1} />M+
        </Reveal>
        <Heading as='h5' sx={styles.text}>
          {textGroup?.[0]}
        </Heading>
      </Heading>
      <Heading as='h3' sx={styles.number}>
        <Reveal effect='fadeInGrow' duration={1}>
        <Counter to={80} />+
        </Reveal>
        <Heading as='h5' sx={styles.text}>
        {textGroup?.[1]}
        </Heading>
      </Heading>
      {/* <Heading variant='h2' sx={styles.number}>
        x€+
        <Heading variant='h4' sx={styles.text}>
        {textGroup[2]}
        </Heading>
      </Heading> */}
    </Box>
  )
}
export default Stats
