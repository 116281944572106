import React from 'react';
import { Layout, Stack, Main } from '@layout';
import CardList from '@components/CardList';
import Divider from '@components/Divider';
import Seo from '@widgets/Seo';
import Testimonial from '@widgets/Testimonial';
import NewsletterExpanded from '@widgets/NewsletterExpanded';
import Hero from '../components/Hero/Hero';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import customSort from '../../../../functions/customSort';
import categoryOrder from '../../../../constants/categoryOrder';
import replaceVariable from '../../../../functions/replaceVariable';

const Posts = ({
  data: { featuredPosts, allBlockContents, posts, site },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props;
  const content = normalizeBlockContentNodes(allBlockContents?.nodes) || {};
  const {siteUrl, locale} = site.siteMetadata;
  const newPosts = replaceVariable({
    texts: posts,
    variables: { BASE_URL: siteUrl + "/" + locale },
  });
  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider />
      {content['post-hero'] && (
        <>
          <Stack>
            <Main>
              <Hero content={content['post-hero']} />
            </Main>
          </Stack>
          <Divider />
        </>
      )}
      {featuredPosts?.nodes?.length > 0 &&  (
        <>
          <Stack>
            <Main>
              <CardList
                nodes={featuredPosts?.nodes}
                limit={6}
                variant='vertical-cover'
                title={content['post-featured'].kicker.text}
                slider
                columns={[1, 2, 3, 3]}
                autoPlay
              />
            </Main>
          </Stack>
          <Divider />
        </>
      )}
      {content['post-body-card'] && (
        <Stack>
          <Main>
            <Testimonial content={content['post-body-card']} />
          </Main>
        </Stack>
      )}
      <Stack effectProps={{effect: false}}>
        <Main>
          {customSort({
            dataset: newPosts.group,
            field: 'categoryName',
            sortArray: categoryOrder,
          }).map((group) => (
            <>
              <Divider />
              <CardList
                nodes={group.nodes}
                limit={4}
                variant='horizontal-md'
                title={group.categoryName}
                key={group.categoryName}
                columns={[1, 2, 2, 2]}
                withTitleLink
              />
            </>
          ))}
        </Main>
      </Stack>
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider />
              <NewsletterExpanded simple />
            </>
          )}
        </Main>
      </Stack>
    </Layout>
  );
};

export default Posts;
