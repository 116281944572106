export default [
  'TV Streaming',
  'Music Streaming',
  'TOP abbonamenti da condividere',
  'TOP suscripciones para compartir',
  'TOP subscriptions to share',
  'Together Price brand',
  'Antivirus',
  'Software',
  'Gaming',
  'Cloud',
  'VPN',
];
